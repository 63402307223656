import './styles.css';

const PVC = () => {
  return <div className={"policy-container-wrapper"}>
    <div className={"policy-container"}>
      <div className={"policy-header"}>
        <div className={"policy-header-title"}>
          SuperLock Privacy Policy
        </div>
        <div className={"policy-header-date"}>
          June 2024
        </div>
      </div>
      <div className={"policy-content"}>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            1. Introduction
          </div>
          <div className={"policy-content-section-text"}>
            <p>
              Welcome to Rosharan Technologies Private Limited (“SuperLock”, “Company”, “we”, “our”, “us”, “SuperLock
              App”).
              This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your
              personal information when you use our product SuperLock, and visit our website superlock.superfam.app
              (collectively, "Services").
            </p>
            <p>
              By using our Services, you consent to the practices described in this Privacy Policy.
            </p>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            2. Information We Collect
          </div>
          <div className={"policy-content-section-text"}>
            <h3>
              2.1. Personal Information
            </h3>
            <ul>
              <li>
                No Personal Identifiable Information (PII)
              </li>
              <li>
                Communications: We may collect information from your communications with us, such as feedback, reviews,
                or
                support requests.
              </li>
            </ul>
            <h3>2.2. Usage Information</h3>
            <ul>
              <li>
                Device Information: Information about the device you use to access our Services, such as your device's
                unique
                identifier, operating system, and browser type.
              </li>
              <li>
                Log Data: Information about your use of our Services, including the pages you visit, the features you
                use, your
                search queries, and the date and time of your interactions.
              </li>
              <li>
                All data is only stored on your device. At no point is SuperFam or its administrators able to read or
                access
                such information.
              </li>
            </ul>
            <h3>
              2.3. Cookies and Tracking Technologies
            </h3>
            <p>
              We use cookies and similar tracking technologies to collect information about your browsing behavior and
              preferences.
            </p>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            3. How We Use Your Information
          </div>
          <div className={"policy-content-section-text"}>
            <p>
              We may use the information we collect for various purposes, including:
            </p>
            <ul>
              <li>To provide and maintain our services.</li>
              <li>To personalize and improve your experience.</li>
              <li>To communicate with you about our services, promotions, and updates.</li>
              <li>To respond to your requests and provide customer support.</li>
              <li>To monitor and analyze usage patterns and trends.</li>
              <li>To protect the security and integrity of our Services.</li>
              <li>To comply with legal obligations.</li>
            </ul>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            4. Disclosure of Your Information
          </div>
          <div className={"policy-content-section-text"}>
            We never sell your data to any 3rd party.
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            5. Your Choices
          </div>
          <div className={"policy-content-section-text"}>
            <ul>
              <li>
                Access, Correct, or Delete: You can access, correct, or delete your all passwords and information on the
                app
                itself
              </li>
              <li>
                App Cache and Storage: You can choose to delete/clear your app cache. Deleting/clearing your storage or
                Uninstalling the app will cause deletion of all end-to-end encrypted data (including all passwords) from
                your
                account. In case you want to change devices - please use the backup feature.
              </li>
            </ul>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            6. Data Security & BACKUP to GOOGLE DRIVE
          </div>
          <div className={"policy-content-section-text"}>
            <p>
              No data ever leaves your device (except for backup to your personal google drive using Google Drives APIs
              and
              adhering to the API policies)</p>
            <p>
              The SuperLock app provides a "Backup from Google Drive" option. This is purely optional. The SuperLock
              app's use
              and transfer to any other app of information received from Google APIs will adhere to <a href={"https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"}>Google API Services
              User
              Data Policy</a>, including the Limited Use requirements. We only access your google drive to create a backup
              of your
              passwords and fetch from the same backup when needed. No other files are fetched or stored. No data is
              stored on
              our servers, only on your device.
            </p>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            7. Changes to this Privacy Policy
          </div>
          <div className={"policy-content-section-text"}>
            <p>
              We may update this Privacy Policy from time to time to reflect changes in our practices or for other
              operational, legal, or regulatory reasons. We will provide notice of any material changes.
            </p>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            8. Contact Us
          </div>
          <div className={"policy-content-section-text"}>
            <p>
              If you have any questions, concerns, or requests regarding this Privacy Policy or your personal
              information,
              please contact us at:
            </p>
            <p>Rosharan Technologies Private Limited</p>
            <p>
              #1207 /343 & 1207 /1/343/1, 9th MAIN, 7th SECTOR , HSR LAYOUT, BANGALORE,
            </p>
            <p>
              KARNATAKA 560102
            </p>
            <p>
              support@superfam.app
            </p>
          </div>
        </div>
        <div className={"policy-content-section"}>
          <div className={"policy-content-section-title"}>
            9. Applicability
          </div>
          <div className={"policy-content-section-text"}>
            This Privacy Policy applies to the use of our Services by users located worldwide.
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default PVC;